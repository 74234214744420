






























import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { UserActionTypes } from '@store/modules/user/Types';
import Vue from 'vue';

import './scss/EmailConfirm.scss';

const NSUser = namespace('storeUser');

@Component({
    name: 'EmailConfirm',
    components: {
        BasePreloader: () => import('@components/BasePreloader/BasePreloader.vue'),
    }
})

export default class EmailConfirm extends Vue {

    @NSUser.Action(UserActionTypes.A_EMAIL_CONFIRM) emailConfirm!: (payload) => Promise<void>;

    pageText: string = '';
    getResponse: boolean = false;

    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        this.emailConfirm({ id: urlParams.get('id') })
            .then(() => {
                this.pageText = 'Почта успешно изменена';
            })
            .catch((err) => {
                this.pageText = err.response?.data?.message || 'Во время смены E-mail произошла ошибка, почта не была изменена. Попробуйте ещё раз';
            })
            .finally(() => {
                this.getResponse = true;
            });
    }
}
